import React from "react"
import classNames from "classnames"

import useBankDetails from "../hooks/useBankDetails"
import styles from "../utils/proofOfPayment.module.scss"

const ProofOfPaymentBankDetails = ({ dispensingChannel }) => {
  const bankData = useBankDetails()

  const bankDetailsElement = bankData.map((bank) => {
    const { bankName, accountName, accountNumber, bankLogo, status } = bank.data
    if (bank.data.dispensingChannel === dispensingChannel && status === "Active") {
      return (
        <div className={classNames("is-flex is-align-items-center", styles["bankDetails"])}>
          <div className="max-h-10 w-25 mr-1 is-flex is-align-items-center is-justify-content-center">
            <img
              src={ bankLogo?.localFiles?.[0]?.childImageSharp?.fluid?.src }
              alt={`${bankName} logo`}
              className={classNames(styles["bankLogo"])}
            />
          </div>
          <div className={classNames("w-75", styles["bankDetailsContainer"])}>
            <h5 className="is-size-6 my-0">{bankName}</h5>
            <p className="is-size-7 my-0">Account name: {accountName}</p>
            <p className="is-size-7 my-0">Account number: {accountNumber}</p>
          </div>
        </div>
      ) 
    }
  })

  return (
    <div className="is-flex is-flex-direction-column is-align-items-center mb-2">
      <p className="is-size-6 has-text-grey">Thank you for ordering your medicines from MedGrocer. Through this form, you can upload your proof of payment for your order.</p>
      <p className="is-size-6 has-text-grey mb-1">You may transfer your payment to:</p>
      <div className={classNames("w-full", styles["paymentCard"])}>
        {bankDetailsElement}
      </div>
    </div>
    
  )
}

export default ProofOfPaymentBankDetails
