import React, { Fragment } from "react"
import classNames from "classnames"

import { formatPrice } from "../../Epharmacy/services/computations"

import { getMedicineTitle, getMedicineBrand } from "../services/medicine"
import getTotals from "../services/getTotals"
import styles from "../utils/proofOfPayment.module.scss"

const ProofOfPaymentOrderSummary = ({ orderDetails, zendeskId }) => {
  const { medicineArray, deliveryFee, dispensingChannel } = orderDetails

  // Checks if the medicine is discounted
  const hasDiscount = medicineArray.some((medicine) => {
    return !!medicine.DiscountedPrice
  })

  const { subtotal, discount, orderTotal } = getTotals({
    medicineArray,
    deliveryFee,
    hasDiscount,
  })

  const medicineDetails = medicineArray.map((medicine) => {
    const hasTwoTitles = medicine.ProductTitle[0].includes("+")
    const hasFreeGoods = medicine.FreeGoodsQuantityFinal > 0
    const isEligible = medicine.IsEligible === 1

    return (
      <Fragment>
        {isEligible && (
          <div className="mb-1">
            <div className="has-text-weight-bold">
              {getMedicineTitle(medicine, hasTwoTitles)}
            </div>
            <div className="is-flex is-justify-content-space-between is-size-7">
              <div>{getMedicineBrand(medicine, hasTwoTitles)}</div>
              <div className="is-flex is-align-items-center">
                <div>
                  Php {formatPrice({ priceString: medicine?.StaticUnitPrice })}
                </div>
                <div className={classNames(styles["grayHighlight"])}>
                  x{medicine.Quantity}
                </div>
              </div>
            </div>
          </div>
        )}
        {hasFreeGoods && (
          <div className="mb-1">
            <div className="has-text-weight-bold">
              {getMedicineTitle(medicine, hasTwoTitles)}
            </div>
            <div className="is-flex is-justify-content-space-between is-size-7">
              <div>
                {getMedicineBrand(medicine, hasTwoTitles)}{" "}
                <span className={classNames(styles["grayHighlight"])}>
                  Medicine Assistance
                </span>
              </div>
              <div className="is-flex is-align-items-center">
                <div>Php {formatPrice({ priceString: 0 })}</div>
                <div className={classNames(styles["grayHighlight"])}>
                  x{medicine.FreeGoodsQuantityFinal}
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  })

  return (
    <div
      className={classNames(
        "is-flex is-flex-direction-column mb-2",
        styles["paymentCard"]
      )}
    >
      <h3 className="mb-0 mt-1 is-size-4">Order Number #{zendeskId}</h3>
      {medicineDetails}
      <hr className={classNames("is-gray", styles["gray-line"])} />
      <div className="is-flex is-justify-content-end">
        <div className="is-flex is-flex-direction-column is-align-items-flex-start">
          <p className="is-size-6">Subtotal</p>
          <p className="is-size-6">Plus: Delivery Fee</p>
          <p className="is-size-5 has-text-primary has-text-weight-bold">
            Order Total
          </p>
        </div>
        <div className="is-flex is-flex-direction-column is-align-items-flex-end ml-2">
          <p className="is-size-6">
            Php {formatPrice({ priceString: subtotal })}
          </p>
          <p className="is-size-6">
            Php {formatPrice({ priceString: deliveryFee })}
          </p>
          <p className="is-size-5 has-text-primary has-text-weight-bold">
            Php {formatPrice({ priceString: orderTotal })}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ProofOfPaymentOrderSummary
