import React from "react"
import { navigate } from "gatsby"

import Loading from "elements/Loading"

// If there's no Zendesk ID, it redirects to the home page
export const hasZendeskID = (zendeskId) => {
  if (!zendeskId) {
    navigate("/")
    return <Loading />
  }
}

// User needs to be on 02 Verified status
// If they are 01, that is an invalid order, and if 03 or above, the order is already verified
export const hasCorrectStatus = (orderDetails) => {
  const { orderStatus } = orderDetails
  const statusNumber = parseInt(orderStatus.substring(0, 2))
  if (statusNumber === 0) {
    navigate("/proof-of-payment/invalid")
    return <Loading />
  } else if (statusNumber === 1) {
    navigate("/proof-of-payment/order-processing")
    return <Loading />
  } else if (statusNumber > 2) {
    navigate("/proof-of-payment/verified-order")
    return <Loading />
  }
}

// If the order is not present, the order is invalid
export const isOrder = (orderDetails) => {
  if (!orderDetails.orderStatus) {
    navigate("/proof-of-payment/invalid")
    return <Loading />
  }
}
