import React from "react"
import Collapsible from "elements/Collapsible"
import guidelines from "./utils/uploadGuidelines.json"

const UploadGuidelinesCollapsible = () => {
  return (
    <Collapsible
      className="has-background-primary"
      title={"Upload Guidelines"}
      color="light"
    >
      <ol>
        {guidelines?.map((item, index) => (
          <li className="my-0 mr-1" key={index}>
            {item}
          </li>
        ))}
      </ol>
    </Collapsible>
  )
}

export default UploadGuidelinesCollapsible
