import React, { useState, useContext, useEffect, useRef } from "react"
import classNames from "classnames"

import UploadDropzoneBody from "./UploadDropzoneBody"
import UploadDropzoneLabel from "./UploadDropzoneLabel"
import UploadGuidelinesModal from "./UploadGuidelinesModal"
import UploadErrorMessage from "./UploadErrorMessage"
import UploadGuidelinesCollapsible from "./UploadGuidelinesCollapsible"

import { AppContext } from "../../../context/AppContext"
import {
  zendeskUploadFiles,
  generateUploadFiles,
} from "services/zendeskService"
import {
  GATSBY_ZENDESK_EMAIL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_SUBDOMAIN,
} from "gatsby-env-variables"
import { uploadDocument } from "./services/uploadDocuments"
import styles from "./utils/upload.module.scss"

// Improvements

const UploadDropzone = ({
  label,
  icon,
  notifications = [],
  filesUploaded,
  setFilesUploaded,
  filesUploadedLimit = 6,
  maxFileSize = 3145728,
  docType,
}) => {
  const { state, dispatch } = useContext(AppContext)
  const fileInputRef = useRef(null)
  const [dropzoneNotifications, setDropzoneNotifications] = useState(notifications)

  // Shows the upload guidelines via modal
  // const handleShowGuidelines = () => {
  //   dispatch({
  //     type: "SHOW_MODAL",
  //     payload: {
  //       isActive: true,
  //       isCard: true,
  //       heading: " ",
  //       content: (
  //         <UploadGuidelinesModal handleFileChooser={handleFileChooser} />
  //       ),
  //     },
  //   })

  //   // Uncomment once sliding modal has been merged
  //   // dispatch({
  //   //   type: "SHOW_SLIDING_MODAL",
  //   //   payload: {
  //   //     isCard: true,
  //   //     hideCloseButton: true,
  //   //     isActive: true,
  //   //     closeOnClickOutside: true,
  //   //     content: (
  //   //       <UploadGuidelinesModal handleFileChooser={handleFileChooser} />
  //   //     ),
  //   //   },
  //   // })
  // }

  // Opens the window where you can choose which files to upload
  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  // Changes the data of the files to follow the format needed
  const handleFileRead =  async (event, docType) => {
    setDropzoneNotifications([])
    const tempFilesUploaded = [...event.target.files]
    let initialNotifications = []
    if (tempFilesUploaded.length + state.documents.length > filesUploadedLimit) {
      initialNotifications.push(
        <UploadErrorMessage
          message={`Please upload only a maximum of ${filesUploadedLimit} files.`}
        />
      )
    }
    else {
      let tempFileStorage = [] 
      // Using a tempFileStorage so all the documents can be put into files uploaded at 
      // the same time rather than one at a time which will cause an upload error
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (tempFilesUploaded[i].size < maxFileSize)
          tempFileStorage.push(await uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            docType,
            handleAlreadyUploaded(setDropzoneNotifications)
          ))
        else
          initialNotifications.push(
            <UploadErrorMessage
              fileName={tempFilesUploaded[i].name}
              message="is greater than 5MB. Please upload a file or photo less than 5MB."
            />
          )
      }
      setFilesUploaded(prevFiles => [...prevFiles, ...tempFileStorage])
    }

    setDropzoneNotifications(prevState => [...prevState, ...initialNotifications])
  }

  // Shows a notif if a file has already been uploaded
  const handleAlreadyUploaded = (setDropzoneNotifications) => (file) => {
    setDropzoneNotifications(prevState => [...prevState, 
      <UploadErrorMessage
        fileName={file.oldname}
        message="has already been uploaded."
      />
    ])
  }

  // Checks if all the documents have tokens already
  useEffect(() => {
    const assignToken = async () => {
      let documentsWithNoToken = filesUploaded?.filter?.((document) => !document?.token)?.length || 0

      if (!state?.activeDocument && documentsWithNoToken > 0) {
        let currentDocumentWithNoToken = filesUploaded?.find?.(
          (document) => !document?.token
        )
        dispatch({
          type: "SAVE_ACTIVE_DOCUMENT",
          payload: currentDocumentWithNoToken,
        })
        await uploadToZendesk({ currentDocument: currentDocumentWithNoToken })
      }
    }
    assignToken()
  }, [filesUploaded])

  // Sets a token for an attachment
  const uploadToZendesk = async ({ currentDocument }) => {
    let documentsWithToken =
    state.documents?.filter?.((document) => !!document?.token)?.length + 1

    let mgcareZendeskConfig = {
      email: GATSBY_ZENDESK_EMAIL,
      apiKey: GATSBY_ZENDESK_API_KEY,
      apiUrl: GATSBY_ZENDESK_SUBDOMAIN,
    }
    
    const UPLOADED_FILES = await zendeskUploadFiles(
      [currentDocument],
      null,
      mgcareZendeskConfig
    )
    const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)
    
    let newTempDocument = {
      ...currentDocument,
      token: GENERATED_FILE_TOKEN?.[0],
    }
  
    let currentDocuments = [...filesUploaded]
    currentDocuments = currentDocuments.filter(
      (document) => document?.oldname !== newTempDocument?.oldname
    )
    currentDocuments = [...currentDocuments, newTempDocument]
    
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: currentDocuments,
    })
    
    dispatch({
      type: "REMOVE_ACTIVE_DOCUMENT",
      payload: {
        ...newTempDocument,
      },
    })
    
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Successfully uploaded ${documentsWithToken} file${
          documentsWithToken > 1 ? "s" : ""
        }`,
        color: "success",
      },
    })
    
    setFilesUploaded(currentDocuments)
  }

  return (
    <div className={classNames("container mt-1", styles["dropzone"])}>
      <UploadGuidelinesCollapsible />
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={(event) => handleFileRead(event, docType)}
        multiple
        accept="image/*, .pdf, .heic"
        value=""
      />
      <div
        className={classNames("has-background-light", styles["dropzone__container"])}
        role={filesUploaded.length > 0 ? "" : "button"}
        tabIndex={0}
        // onKeyDown={(event) => {
        //   if (event.key === "Enter" && filesUploaded.length == 0)
        //     handleFileChooser()
        // }}
        // onClick={handleFileChooser}
        // onClick={filesUploaded.length === 0 ? handleShowGuidelines : null}
      >
        <UploadDropzoneBody
          icon={icon}
          filesUploaded={filesUploaded}
          setFilesUploaded={setFilesUploaded}
          state={state}
          dispatch={dispatch}
          notifications={dropzoneNotifications}
        />
        {dropzoneNotifications.length > 0 && dropzoneNotifications.map((item) => item)}
        {!filesUploadedLimit || filesUploaded.length < filesUploadedLimit ? (
          <UploadDropzoneLabel
            label={label}
            onKeyDown={(event) => {
              if (event.key === "Enter") handleFileChooser()
            }}
            onClick={handleFileChooser}
            // onClick={
            //   filesUploaded.length > 0
            //     ? handleFileChooser
            //     : handleShowGuidelines
            // }
          />
        ) : null}
      </div>
    </div>
  )
}

export default UploadDropzone


// UPLOADED_FILES NS
// [
//   {
//       "data": "<!DOCTYPEhtml><htmlclass=\"has-navbar-fixed-top\"><head><meta>",
//       "status": 200,
//       "statusText": "OK",
//       "headers": {
//           "accept-ranges": "bytes",
//           "access-control-allow-origin": "*",
//           "cache-control": "public, max-age=0",
//           "connection": "close",
//           "content-length": "1635",
//           "content-type": "text/html; charset=UTF-8",
//           "date": "Fri, 22 Sep 2023 03:45:44 GMT",
//           "etag": "W/\"663-18abae5cf65\"",
//           "last-modified": "Fri, 22 Sep 2023 03:18:57 GMT",
//           "x-powered-by": "Express"
//       },
//       "config": {
//           "url": "/uploads.json?filename=pop_20230922_114544",
//           "method": "post",
//           "data": {},
//           "headers": {
//               "Accept": "application/json, text/plain, */*",
//               "Content-Type": "application/binary",
//               "Authorization": "Basic dW5kZWZpbmVkL3Rva2VuOnVuZGVmaW5lZA=="
//           },
//           "transformRequest": [
//               null
//           ],
//           "transformResponse": [
//               null
//           ],
//           "timeout": 0,
//           "xsrfCookieName": "XSRF-TOKEN",
//           "xsrfHeaderName": "X-XSRF-TOKEN",
//           "maxContentLength": -1,
//           "maxBodyLength": -1
//       },
//       "request": {}
//   }
// ]


// UPLOADED_FILES JN
// [
//   {
//       "data": {
//           "upload": {
//               "token": "TEuWkghSFlpRMfMQAGvdKRXql",
//               "expires_at": "2023-09-25T03:43:37Z",
//               "attachments": [
//                   {
//                       "url": "https://mgjnj.zendesk.com/api/v2/attachments/23248929011225.json",
//                       "id": 23248929011225,
//                       "file_name": "pop_20230922_114337",
//                       "content_url": "https://mgjnj.zendesk.com/attachments/token/t5nFfbg8BW2Dbf1ST8EE9Tasb/?name=pop_20230922_114337",
//                       "mapped_content_url": "https://mgjnj.zendesk.com/attachments/token/t5nFfbg8BW2Dbf1ST8EE9Tasb/?name=pop_20230922_114337",
//                       "content_type": "image/jpeg",
//                       "size": 56199,
//                       "width": 590,
//                       "height": 393,
//                       "inline": false,
//                       "deleted": false,
//                       "malware_access_override": false,
//                       "malware_scan_result": "not_scanned",
//                       "thumbnails": [
//                           {
//                               "url": "https://mgjnj.zendesk.com/api/v2/attachments/23248920800921.json",
//                               "id": 23248920800921,
//                               "file_name": "pop_20230922_114337_thumb",
//                               "content_url": "https://mgjnj.zendesk.com/attachments/token/SdUPgvX7QNlalY2Is2N38sI4n/?name=pop_20230922_114337_thumb",
//                               "mapped_content_url": "https://mgjnj.zendesk.com/attachments/token/SdUPgvX7QNlalY2Is2N38sI4n/?name=pop_20230922_114337_thumb",
//                               "content_type": "image/jpeg",
//                               "size": 2600,
//                               "width": 80,
//                               "height": 53,
//                               "inline": false,
//                               "deleted": false,
//                               "malware_access_override": false,
//                               "malware_scan_result": "not_scanned"
//                           }
//                       ]
//                   }
//               ],
//               "attachment": {
//                   "url": "https://mgjnj.zendesk.com/api/v2/attachments/23248929011225.json",
//                   "id": 23248929011225,
//                   "file_name": "pop_20230922_114337",
//                   "content_url": "https://mgjnj.zendesk.com/attachments/token/t5nFfbg8BW2Dbf1ST8EE9Tasb/?name=pop_20230922_114337",
//                   "mapped_content_url": "https://mgjnj.zendesk.com/attachments/token/t5nFfbg8BW2Dbf1ST8EE9Tasb/?name=pop_20230922_114337",
//                   "content_type": "image/jpeg",
//                   "size": 56199,
//                   "width": 590,
//                   "height": 393,
//                   "inline": false,
//                   "deleted": false,
//                   "malware_access_override": false,
//                   "malware_scan_result": "not_scanned",
//                   "thumbnails": [
//                       {
//                           "url": "https://mgjnj.zendesk.com/api/v2/attachments/23248920800921.json",
//                           "id": 23248920800921,
//                           "file_name": "pop_20230922_114337_thumb",
//                           "content_url": "https://mgjnj.zendesk.com/attachments/token/SdUPgvX7QNlalY2Is2N38sI4n/?name=pop_20230922_114337_thumb",
//                           "mapped_content_url": "https://mgjnj.zendesk.com/attachments/token/SdUPgvX7QNlalY2Is2N38sI4n/?name=pop_20230922_114337_thumb",
//                           "content_type": "image/jpeg",
//                           "size": 2600,
//                           "width": 80,
//                           "height": 53,
//                           "inline": false,
//                           "deleted": false,
//                           "malware_access_override": false,
//                           "malware_scan_result": "not_scanned"
//                       }
//                   ]
//               }
//           }
//       },
//       "status": 201,
//       "statusText": "",
//       "headers": {
//           "cache-control": "max-age=0, private, must-revalidate",
//           "content-length": "2273",
//           "content-type": "application/json; charset=utf-8"
//       },
//       "config": {
//           "url": "/uploads.json?filename=pop_20230922_114337",
//           "method": "post",
//           "data": {},
//           "headers": {
//               "Accept": "application/json, text/plain, */*",
//               "Content-Type": "application/binary",
//               "Authorization": "Basic am5qLWxlYWRAbWVkZ3JvY2VyLmNvbS90b2tlbjoxVlQ4ZHBsNTZrdnpVSXk2c1hRcGJqc1dDR0llWnNVc1ZiZkp1TWNE"
//           },
//           "baseURL": "https://mgjnj.zendesk.com/api/v2",
//           "transformRequest": [
//               null
//           ],
//           "transformResponse": [
//               null
//           ],
//           "timeout": 0,
//           "xsrfCookieName": "XSRF-TOKEN",
//           "xsrfHeaderName": "X-XSRF-TOKEN",
//           "maxContentLength": -1
//       },
//       "request": {}
//   }
// ]
